@import "variables";
@import "mixins";

html, body {
	height: 100%;
	padding: 0;
	margin: 0; }

div#main {
	font-family: "Source Sans Pro", sans-serif, helvetica;
	font-size: 1em;
	line-height: 1.34em;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: $width;
	padding: 0 18px; }

a {
	@extend .t3;

	color: $baseColor2;
	text-decoration: none;

	&:hover {
		color: $baseColor1; } }

ul {
	list-style: none;
	padding: 0;
	margin: 0; }

h1, h2, h3, h4, h5, h6 {
	color: $baseColor1;
	line-height: 1.1em;
	margin: 0;
	margin-bottom: 10px; }
